/**
 * We need to use this function for all URLs, because
 * when someone uses any language other than Russian,
 * we need to prefix URLs with /$language.
 */
export default (uri: string) => {
    if (uri[0] !== '/') {
        uri = `/${uri}`
    }

    return Locale === DefaultLocale ? uri : `/${Locale}${uri}`
}
