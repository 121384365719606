import toast from 'toastify-js'

interface ShowToastParams {
    text: string
    success?: boolean
    url?: string
    duration?: number
    withSound?: boolean
}

export default (params: ShowToastParams) => {
    if (typeof params.success === 'undefined')
        params.success = true

    const config: toast.Options = {
        text: `
        <div class="flex items-center gap-6">
            <i
                class="lni fas ${params.success ? 'lni-checkmark fa-check ' : 'lni-sad fa-times'}"
                style="margin-right: 8px; color: ${params.success ? 'green' : 'red'}"
                aria-hidden="true"
            ></i>
            <span>${params.text}</span>
        </div>
        `,
        destination: params.url || 'javascript:',
        escapeMarkup: false,
        gravity: 'bottom',
        position: 'right',
        close: true,
        duration: params.duration || 5000,
        style: {
            background: 'white',
            color: 'black',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
            right: '30px',
            borderRadius: '10px',
            padding: '25px 50px',
            minWidth: '380px',
        },
    }

    if (window.screen.availWidth < 992)
        config.gravity = 'bottom'

    if (params.withSound) {
        new Audio('/storage/new-message.mp3')
            .play()
            .catch(err => console.error(err))
    }

    toast(config).showToast()
}
