<script setup lang="ts">
import { onMounted, ref } from 'vue'
import tippy, { Placement } from 'tippy.js'

interface Props {
    content: string | undefined
    icon?: boolean
    placement?: Placement
}


const { content, icon = false, placement = 'top' } = defineProps<Props>()
const el = ref<HTMLElement | null>(null)

onMounted(() => {
    if (el.value) {
        tippy(el.value, {
            placement,
        })
    }
})
</script>

<template>
    <span v-if="content" ref="el" :data-tippy-content="content">
        <i
            v-if="icon"
            class="fas fa-question-circle lni lni-question-circle mr-2 font-bold text-main"
            aria-hidden="true"
        ></i>
        <slot></slot>
    </span>

    <slot v-else />
</template>
